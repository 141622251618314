import { Title } from '@solidjs/meta';
import { useNavigate, useSubmission } from '@solidjs/router';
import { clientAction } from '@troon/api-client';
import { IconLogo } from '@troon/icons/logo';
import { Button, Errors, Form, Heading, Input, Label, TextField } from '@troon/ui';
import { createEffect } from 'solid-js';

export default function ForgotPassword() {
	const navigate = useNavigate();
	const submission = useSubmission(forgotAction);

	createEffect(() => {
		if (submission.result) {
			navigate(`/auth/reset-password?email=${submission.result.email}`, { replace: true });
		}
	});

	return (
		<>
			<Title>Forgot Password | Troon Operator</Title>
			<IconLogo title="Troon" class="w-32" />
			<div class="flex flex-col items-center gap-2">
				<Heading as="h1" size="h2" class="text-center">
					Troon Operator
				</Heading>
				<p class="text-center">
					Forgot your password? Enter your email address to receive instructions to reset your password.
				</p>
			</div>
			<Form action={forgotAction} method="post" class="w-full">
				<TextField name="email">
					<Label>Email address</Label>
					<Input inputMode="email" autocomplete="email" />
				</TextField>

				<Errors />

				<Button type="submit">Reset Password</Button>
			</Form>
		</>
	);
}

const forgotAction = clientAction('POST', '/v0/operator/auth/forgot-password');
